<script setup>
import mitt from 'mitt';
import { SignInNudge, AccountSignInNudge } from '@rei/account-flyout';
import { onBeforeMount, ref, computed, onMounted } from 'vue';

const props = defineProps({
  user: {
    type: Object,
    default: null,
  },
  signInNudgeExperience: {
    type: String,
    default: 'control',
  },
  signInCreateAccountExperience: {
    type: String,
    default: 'control',
  },
});

const { pathname } = window.location;

const isEligibleForTest = ref(false);
const open = ref(false);
const animate = ref(false);
const isDesktop = ref(window.matchMedia('(min-width: 992px)').matches);

// Refactored previous logic to use computed values to check what page is loaded
const isSearchPage = computed(() => {
  const initialPropsHTML = document.getElementById('initial-props')?.innerHTML;
  let initialProps = null;

  try {
    initialProps = initialPropsHTML ? JSON.parse(initialPropsHTML) : null;

    return Boolean(initialProps?.ProductSearch);
  } catch (error) {
    console.error('Failed to parse initialPropsHTML', error);

    return false;
  }
});
const isHomePage = computed(() => ['', '/'].includes(pathname));
const isCorePDP = computed(() => /^\/product/.test(pathname));
const isCartPage = computed(() => ['/ShoppingCart'].includes(pathname));

const signInNudgeEventBus = mitt();
const gNav = ref(document.querySelector('.gnav'));
const isWhiteNav = computed(() => gNav.value !== null);

const signInLink = ref(document.querySelector("[data-ui='login-module-greeting']"));
const signInNudge = ref(document.querySelector('.sign-in-nudge-dropdown'));
const navbarWrapper = ref(document.querySelector("[data-ui='site-navigation']"));

const shouldShowAccountSignInNudge = computed(
  () =>
    props.signInNudgeExperience !== 'control' &&
    ['variant-1', 'variant-2'].includes(props.signInNudgeExperience),
);

const toggle = () => {
  open.value = !open.value;

  if (open.value) {
    window?.metrics?.link({ linkName: 'sign_in_nudge:open' });
  }
};

// Temporary elegible value, can be removed after APEX-3858 is finished.
const sendIsEligible = () => {
  isEligibleForTest.value = true;
  window?.metrics?.link({ linkName: 'SigninNudge_AccountCreateFocus' });
};

const handleClose = () => {
  open.value = false;
  signInLink.value.setAttribute('aria-expanded', 'false');

  if (navbarWrapper.value) {
    navbarWrapper.value.style.overflowY = 'initial';
  }

  const nudgeClasses = [
    'sign-in-nudge__container--flyout-login',
    'sign-in-nudge__container--flyout-registration-link',
  ];

  const isFocusOnNudge = nudgeClasses.includes(document.activeElement.classList[0]);
  if (isFocusOnNudge) {
    signInLink.value.focus();
  }

  if (isWhiteNav.value) {
    gNav.value.setAttribute('data-nudge', 'false');
    document.body.classList.remove('no-scroll');

    setTimeout(
      () => {
        signInNudge.value.style.display = 'none';
      },
      isDesktop.value ? 0 : 400,
    );
  }
};

const clickOutsideHandler = (event) => {
  const excludeElements = ['login-module-greeting'];

  if (
    !open.value ||
    excludeElements.includes(event.target.getAttribute('data-ui')) ||
    signInLink.value.contains(event.target)
  )
    return;

  if (!signInNudge.value.contains(event.target)) {
    signInNudgeEventBus.emit('closed');
  }
};

const openSignInNudge = () => {
  toggle();
  sendIsEligible();

  document.body.classList.remove('sign-in-open');
  signInLink.value.setAttribute('aria-expanded', 'true');

  if (navbarWrapper.value) {
    navbarWrapper.value.style.overflowY = !isDesktop.value ? 'hidden' : 'initial';
  }
  signInNudge.value.style.display = 'block';

  if (isWhiteNav.value && isDesktop.value) {
    gNav.value.setAttribute('data-nudge', 'true');
    document.body.classList.add('no-scroll');
  }

  setTimeout(() => {
    animate.value = true;
  }, 1000);
};

const checkTimeSinceNudge = (lastOpenedKey) => {
  const thirtyMinuteMs = 30 * 60 * 1000;
  const thirtyMinutesAgo = +new Date() - thirtyMinuteMs;
  const nudgeLastOpened = parseInt(window.localStorage.getItem(lastOpenedKey), 10);
  const openedWithinLast30Min = nudgeLastOpened > thirtyMinutesAgo;

  if (openedWithinLast30Min || props.user.isLoggedIn) return;

  window.localStorage.setItem(lastOpenedKey, String(+new Date()));
  isEligibleForTest.value = true;
  openSignInNudge();
};

const shouldSignInNudgeLoad = () => {
  setTimeout(() => {
    const myStoreOpen = sessionStorage.getItem('myStoreOpen') === 'true';

    if (myStoreOpen) return;

    if (!(isHomePage.value || isCorePDP.value || isCartPage.value || isSearchPage.value)) return;

    if (isHomePage.value || isCorePDP.value || isSearchPage.value) {
      checkTimeSinceNudge('login-opened');
    } else if (isCartPage.value) {
      checkTimeSinceNudge('cart-opened');
    }
  }, 1000);
};

const closed = () => {
  signInNudgeEventBus.emit('closed');
};

const onEscape = () => {
  signInNudgeEventBus.emit('closed');
};

/**
 * This function is only used on the search page to remove the signInNudge when the filters component is places sticky on the page
 * @param {HTMLElement[]} mutations - An array of HTML Objects representing mutated elements
 */
const handleSearchPageMutation = (mutations, observer) => {
  mutations.forEach((mutation) => {
    if (mutation.type === 'attributes') {
      const elem = mutation.target;

      if (mutation.attributeName === 'stuck') {
        const isStuck = elem.hasAttribute('stuck');

        if (isStuck) {
          open.value = false;
          observer.disconnect();
        }
      }
    }
  });
};

/**
 * This function is only used on the PDP page to remove the signInNudge when a dynamic sticky element is added to the page
 * @param {HTMLElement[]} mutations - An array of HTML Objects representing mutated elements
 */
const handlePDPPageMutation = (mutations, observer) => {
  mutations.forEach((mutation) => {
    if (mutation.type === 'childList') {
      const isStickyShown = Boolean(mutation.addedNodes.length);

      if (isStickyShown) {
        open.value = false;
        observer.disconnect();
      }
    }
  });
};

const handleAccountSignInNudge = () => {
  if (isSearchPage.value) {
    const observerConfig = { attributes: true };
    const observer = new MutationObserver(handleSearchPageMutation);
    const targetElements = Array.from(
      document.querySelector('#search-results').parentElement.children,
    );

    targetElements.forEach((target) => {
      observer.observe(target, observerConfig);
    });
  } else if (isCorePDP.value) {
    const observerConfig = { childList: true };
    const observer = new MutationObserver(handlePDPPageMutation);
    const targetElement = document.querySelector('.product-page__atc-sticky-wrapper');

    observer.observe(targetElement, observerConfig);
  }
};

onMounted(() => {
  shouldSignInNudgeLoad();
  signInNudgeEventBus.on('closed', handleClose);
  signInLink.value.setAttribute('aria-expanded', 'false');
  signInLink.value.setAttribute('aria-controls', 'sign-in-nudge');
  window.addEventListener('click', clickOutsideHandler, true);

  handleAccountSignInNudge();
});

onBeforeMount(() => {
  signInNudgeEventBus.off('closed', handleClose);
  window.removeEventListener('click', clickOutsideHandler, true);
});
</script>

<template>
  <!-- Only shows up on small and medium screen -->
  <AccountSignInNudge
    v-if="shouldShowAccountSignInNudge"
    :is-eligible-for-test="isEligibleForTest"
    :variant="signInNudgeExperience"
    :open="open"
    :animate="animate"
    @close-nudge="closed"
    @key-escape="onEscape"
  />
  <!-- Only shows up on desktop screen  -->
  <SignInNudge
    :is-eligible-for-test="isEligibleForTest"
    :experience="signInCreateAccountExperience"
    :open="open"
    :trap-tab-inside-widget="false"
    @closed="closed"
    @key-escape="onEscape"
  />
</template>
